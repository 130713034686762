import logo from "./logo.webp"

const  clientData = {
   client_entity: 20,
   attorney_firm: 'laputkalaw.com',
    attorney_name: 'Charles Laputka',
    attorney_number: '(610) 477-0155',
    attorney_email: 'claputka@laputkalaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_laputka_law+(720p).mp4',
    mainColor: '#003660',
    secondaryColor: '#333333',
    siteLink: 'https://www.laputkalaw.com',
    logo
}

export default clientData